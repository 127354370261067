* {
    box-sizing: border-box;   

}


.layout {
    min-height: 100vh;
    display: flex;

    flex-direction: column;
}


body {

    display: flex;
    flex-direction: column;

    margin: 0;
    font-family: Poppins, sans-serif;

    background-color: black;


}


main {
    display: flex;
    flex-direction: column;
    flex: 1;
}


.notfound--page{

    display: flex;
    /* justify-content: space-between; */
    align-items: center;

    flex-direction: column;

    color: white;

    /* height: 100vh; */
}


.notfound--page .error{
    font-size: 7rem;
}

nav {

    height: 70px;
    padding: 20px 36px;
    /*box-shadow: 0px 2.98256px 7.4564px rgba(0,0,0,0.1);*/
    
    display: flex;
    flex-wrap: wrap;
    
    justify-content: space-between;
    align-items: center;
    
    background-color: black;
    
    flex-direction: row;

    
}


.nav--menu {

  display: inline-block;
    

  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
 

}


.nav--menu > li {
  display: inline-block;
  color: white;

  text-align: center;
  padding: 10px;
  text-decoration: none;
}


.navbar--button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

a {
    text-decoration: unset;
    color: white;
}

section {

    padding: 20px;
    background-color: black;

}

.nav--logo {
    
    max-width: 100px;
    
    /*align-self: center;*/
}



.footer--links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    
}


.footer--links > li {

  display: inline;
  text-align: center;
  padding: 30px;
  text-decoration: none;
  color: black;

}




.nav--menu {
    
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  overflow: hidden;

}


.nav--menu > li {
  display: inline;
  color: white;
  text-align: center;
  padding: 30px;
  text-decoration: none;
}



.newsletter--header h2{

    font-weight: 200;
    font-size: 2rem;
    color: white;


    margin-top: 2rem;

    text-align: center;

}


.newsletter--section{
    
    display: flex;
    flex-direction: column;


    margin: auto;
    /*border-color: white;*/
    color: white;

}



.newsletter--form {

    /* font-weight: 200; */
    
    margin: auto;
    
    width: 70%;
    max-width: 70%;
    
    display: grid;
    /*grid-template: 40px 40px / 1fr 1fr;*/
    
    border-color: white;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;

    
    padding: 20px;
    
    gap: 15px;
    
}

.newsletter--email {

    /* display: flex; */
    
    /* flex-wrap: wrap; */

    border-radius: 5px;
    border: 1px solid;
    text-indent: 20px;

    height: 3rem;

    background-color: black;
    color: white;

    font-size: 24px;
    
}



.newsletter--button {
    
    grid-column: 1 / -1;
    
    background-color: black;
    color: white;
    font-size: 24px;

    border-style: none;

    
}


.incomplete--form{
    color: red;
}

.newsletter--confirm {
    
  /*height: 200px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  
  flex-direction: column;

  color: red;
  
 
  
  border-color: white;
  border-style: solid;
  border-width: 1px;
  
  max-width: 50%;
  margin: auto;
  
  padding: 10px;
  
  flex-wrap: wrap;
  
}

.newsletter--confirm > h1 {
    padding: 10px;
    margin: auto;
    
font-family: Poppins;
  font-weight: 200;
    
}

.newsletter--confirm > h2 {
    padding: 10px;
    margin: auto;
    
    font-family: Poppins;
    font-weight: 200;
}


footer {
    
    /*height: 74px;*/
    padding: 20px 36px;
    
    box-shadow: 0px -25px 20px -20px rgba(0,0,0,0.45);
    
    display: flex;
    
    justify-content: space-between;
    align-items: center;

    flex-direction: row;
 
    max-height: 50px;
    
    /* margin-top: auto; */
 
    /* margin: auto; */

    background-color: white;

    color: black;
    
}





.about--page {

    color: white;
    
    padding: 10 50px;
    
    
    display: flex;
    
    flex-direction: column;
    flex-wrap: wrap;
    
}

p {
    font-size: 24px;
}



.link--current {
    color: red;
    font-weight: bold;
}


.link--noncurrent{
    color: black;
    
}

a:hover {
    font-weight: bold;
    color: red;
}





.homepage--attributions{
    display: flex;
    justify-content: center;
    gap: 3rem;

}


.poweredby--openai{
    max-height: 2rem;
}

.poweredby--aws{
    max-height: 2rem;
}

.poweredby--react{
    max-height: 2rem;

}


.homepage--banner{
    
    padding-top: 3rem;
    padding-left: 6rem;
    padding-right: 3rem;
    align-items: center;
    
    /* justify-content: center; */
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    
    justify-content: flex-start;
    
}


.homepage--comingsoon{
    /* padding-top: 3rem; */
    /* padding-left: 6rem; */
    /* padding-right: 3rem; */
    align-items: center;

    justify-content: center;
    
    display: flex;
    flex-direction: column;

    flex-grow: 1;
}


.main--header {

    
    margin-bottom: 16px;
    color: white;
    font-size: 36px;
    
    font-family: Poppins;
    font-weight: lighter;
}

.main--subheader{
    
    color: white;
    font-size: 26px;
    font-family: Poppins;
    font-weight: lighter;

    padding-bottom: 0.1rem;
}


.main--text {
    margin-top: 0;
    color: white;
    font-size: 18px;
    font-weight: lighter;
    
}



.homepage--container {

    background-color: black;

    display: flex;
    flex-direction: column;

    flex-grow: 1;


}

.homepage--notes{
    margin-top: 7rem;



}



.homepage--notes-header{
    padding-left: 4rem;
    color: white;

    font-family: Poppins;
    font-weight: lighter;
}
